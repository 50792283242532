body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav.site-header {
  border-bottom: solid 1px #cccccc;
  font-size: 1.1em;
  color: #333;
  background-color: #fff;
}

nav.site-header a:link {
  color: #333;
}

nav.site-header a:visited {
  color: #333;
}

.email-address {
  padding: 3px;
  position: relative;
  top: 7px;
  color: #111;
  background: #f0f0f0;
}
