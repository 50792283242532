.Newsletters {
  text-align: left;
}

a.subject-links {
  color: #333;
}
a.subject-links:hover {
  color: #000;
}
a.subject-links:visited {
  color: #333;
}
